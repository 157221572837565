html,
body {
  word-break: break-word;
  padding: 0;
  margin: 0;
  background-color: var(--background);
  color: var(--main-text);
  font-family: Arimo -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
  margin: 0;
  padding: 0;
}

p {
  line-height: 2em;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
}

ol,
ul {
  position: relative;
  display: inline-block;
  list-style: none;
  width: 100%;
  margin: auto;
  padding: 0;
}

li {
  line-height: 2rem;
}

* {
  box-sizing: border-box;
}

h1,
h2 {
  margin: 0;
  font-size: 4rem;
  color: var(--title);
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
}


h2 {
  font-size: 2rem;
  margin: 2rem 0;
  font-family: 'Courier New', Courier, monospace;
}

h3 {
  font-size: 1.3rem;
  color: var(--title);
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }

  h2 {
    font-size: 2rem;
  }
}

section {
  margin: 4rem 0;
  max-width: 64ch;
}

/* Code for Firefox */
::-moz-selection,
::selection {
  color: var(--background);
  background: var(--title);
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway-Medium'), url('/fonts/Raleway-Medium.woff2') format('woff2');
  font-display: fallback;
  font-style: normal;
}

table {
  border-collapse: collapse;
  margin: 25px 0;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  color: var(--background-dark);
}

table thead tr {
  background-color: var(--green);
  color: var(--white);
  text-align: left;
}

table th,
table td {
  padding: 12px 15px;
  font-size: 0.9rem;
}

table tbody tr {
  border-bottom: 1px solid #dddddd;
}

table tbody tr:nth-of-type(even) {
  background-color: var(--white);
}

table tbody tr:nth-of-type(odd) {
  background-color: var(--white);
}

table tbody tr:last-of-type {
  border-bottom: 2px solid var(--green);
}

blockquote {
  background-color: var(--blue-gray);
  padding: var(--padding) var(--padding-2x);
  border-radius: var(--border-radius);
}