:root {
  --green: #8ef68a;
  --secondary-green: #8AF6BC;
  --dark: #18262c;
  --dark-bg: #07131afa;
  --light-gray: #97aab1;
  --dark-gray: #3f4f55;
  --blue-gray: #1a1b22;
  --white: #FFF;
  --background: var(--dark);
  --background-dark: var(--dark-bg);
  --title: var(--green);
  --link: var(--title);
  --subtitle: var(--white);
  --border-sm: 4px;
  --main-text: var(--light-gray);
  --secondary-text: var(--dark-gray);
  --margin: 1rem;
  --margin-2x: 2rem;
  --margin-3x: 3rem;
  --padding: 1rem;
  --padding-2x: 2rem;
  --padding-3x: 3rem;
  --transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
  --transition-reveal: all 0.35s linear;
  --width-ch: 80ch;
  --navbar-height: 100px;
  --border-radius: 4px;
  --font-sm: 0.9rem;
}