.container {
  font-family: 'Raleway';
  display: grid;
  grid-template-rows: var(--navbar-height) auto 1fr;
}

.nav {
  padding: 0 var(--padding-2x);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav .menu {
  margin: var(--margin-nav);
  display: block;
}

.navLink {
  background-image: linear-gradient(transparent 0%,
      transparent 90%,
      var(--title) 90%,
      var(--title) 100%);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  background-position-x: right;
  transition: background-size 300ms;
}

.navLink:hover {
  background-size: 100% 100%;
  background-position-x: left;
}

.icon {
  padding: var(--padding) 0;
  font-size: var(--font-sm);
}

.icon:hover {
  color: var(--title);
  transform: translateY(-3px);
  transition: var(--transition);
}

.iconLink {
  transition: var(--transition);
}

.iconLink:hover {
  color: var(--title);
  transform: translateY(-3px);
  transition: var(--transition);
}

.iconContainer {}

.aboutMe a {
  color: var(--title);
}

.searchResultItem {
  padding: var(--padding-2x) var(--padding-2x);
  margin: var(--margin) 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  max-width: 64ch;
}

.searchResults {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.searchLink {
  color: var(--green);
  width: 100%;
  height: 100%;
}

.searchInput {
  padding: var(--padding);
}

.nav .burgerIcon {
  margin: 0;
  padding: 0.5rem;
}

.hidden {
  visibility: hidden;
}

.nav .burger {
  width: 30px;
  height: 2px;
  background-color: var(--title);
  margin: 8px 0;
  border-radius: var(--border-radius);
}

.menu ul {
  display: flex;
  gap: var(--padding-2x);
}

.main {
  background-color: var(--main-color);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: var(--transition);
  padding: var(--padding);
}

.main .introductionHeader {
  color: var(--subtitle);
  margin: .5rem 0;
  font-size: 2.5rem;
  line-height: 1.2em;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace;
}

.main p {
  color: var(--main-text);
}

.introduction {
  color: var(--main-text);
  font-size: 1.2rem;
  line-height: 2em;
  font-family: 'Raleway';
}

.name {
  margin: var(--margin-2x) 0;
  line-height: 3rem;
}

.footer {
  display: flex;
  flex: 1;
  padding: var(--padding-2x) 0;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transition: var(--transition);
  text-align: center;
}


.footer .socialMedia a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.sidebar {
  position: fixed;
  width: 60vw;
  height: 100vh;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background-dark);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  visibility: visible;
  transition: var(--transition);
  gap: var(--padding-2x);
}

.sidebar.sidebarHidden {
  transform: translateX(100vw);
}

.sidebarMenu ul {
  margin: var(--margin-2x);
  transition: var(--transition);
  display: flex;
  flex-direction: column;
  gap: var(--padding-2x);
}

.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.7;
}

.close:hover {
  opacity: 1;
}

.close:before,
.close:after {
  position: absolute;
  left: var(--margin);
  content: ' ';
  height: 2rem;
  width: 2px;
  background-color: var(--title)
}

.close:before {
  transform: rotate(45deg);
}

.close:after {
  transform: rotate(-45deg);
}

.socialMedia {
  display: flex;
}

.socialMedia.side {
  position: fixed;
  bottom: 48px;
  left: 24px;
  flex-direction: column;
}

.socialMedia svg {
  padding: var(--padding);
}

.socialMedia.footer {
  flex-direction: row;
}




.btn {
  margin: var(--margin-2x) 0;
  padding: var(--padding) var(--padding-2x);
  background-color: var(--background);
  color: var(--title);
  border: 1px solid var(--title);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: var(--transition);
}

.btn.pressed {
  transform: translateY(-5px)
}

.btn:hover {
  background-color: var(--title);
  color: var(--background);
  box-shadow: 0 0 30px var(--title);
}

.portfolio {
  max-width: 1000px;
}

.portfolioItems {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem 0;
}

.portfolioContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: var(--margin) 0;
}

.portfolioImageContainer {
  width: 50%;
}

.portfolioImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius);
  filter: brightness(75%);
  transition: var(--transition);
}

.portfolioImage:hover {
  filter: brightness(100%);
}

.portfolioInfo {
  width: 50%;
  transform: translateX(-50px);
  display: flex;
  flex-direction: column;
}

.portfolioInfo h4 {
  color: var(--title);
}

.portfolioDescription {
  background-color: var(--background-dark);
  padding: var(--padding-2x);
  border-radius: var(--border-radius);
  box-shadow: -10px 10px 20px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -10px 10px 20px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -10px 10px 20px 0px rgba(0, 0, 0, 0.75);
}

.portfolioBtn {
  text-align: center;
}

.stack {
  width: calc(100% - 80px);
  margin: var(--margin-2x) 0;
  font-size: 0.9rem;
  font-family: 'Consolas', 'Courier New', Courier, monospace;
  line-height: 2rem;
}

.projects {
  max-width: 1000px;
}

.projectsContainer {
  width: 100%;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.project {
  background-color: var(--background-dark);
  padding: var(--padding-2x);
  border-radius: var(--border-radius);
  width: 300px;
  box-shadow: -5px 5px 20px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -5px 5px 20px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -5px 5px 20px 0px rgba(0, 0, 0, 0.75);
  transition: var(--transition);
}

.projectItem {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  border-radius: var(--border-radius);
  box-shadow: -10px 10px 20px 2px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: -10px 10px 20px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -10px 10px 20px 2px rgba(0, 0, 0, 0.75);
  transition: var(--transition);
  z-index: -1;
}

.project:hover {
  transform: translateY(-5px);
}

.project:hover:before {
  opacity: 1;
}

.projectItemDescription {
  margin: var(--margin) 0;
}

.projectIcons {
  display: flex;
  justify-content: space-between;
}

.unrevealed {
  opacity: 0;
  transform: translateY(-25px);
  transition: var(--transition-reveal);
}

.revealed {
  opacity: 100;
  transition: var(--transition-reveal);
}

.mY {
  margin: var(--margin-3x) 0;
}

.credits {
  display: flex;
  flex-direction: column;
  gap: var(--margin-2x);
}

@media (max-width: 768px) {
  .main.blurry {
    filter: blur(4px);
  }

  .footer.blurry {
    filter: blur(4px);
  }

  .nav {
    padding: 0 var(--padding);
  }

  .nav .menu {
    display: none;
  }

  .socialMedia.side {
    visibility: hidden;
  }

  .portfolioContainer {
    flex-direction: column;
  }

  .portfolioImageContainer {
    width: 100%;
  }

  .portfolioInfo {
    width: 100%;
    transform: translateX(0);
    text-align: justify;
  }

  .projectItem {
    width: 100%;
  }

  .icon {
    margin-right: var(--margin);
  }

  .stack {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .sidebar {
    transform: translateX(100vw);
  }

  .nav .burger {
    display: none;
  }

  .socialMedia.footer {
    visibility: hidden;
  }

  .portfolioContainer:nth-child(odd) .portfolioInfo {
    align-items: flex-end;
  }

  .portfolioItems .portfolioContainer:nth-child(even) {
    flex-direction: row-reverse;
  }

  .portfolioItems .portfolioContainer:nth-child(even) .icon {
    margin-right: var(--margin);
  }

  .portfolioItems .portfolioContainer:nth-child(odd) .icon {
    margin-left: var(--margin);
  }

  .portfolioItems .portfolioContainer:nth-child(even) .portfolioInfo {
    transform: translateX(50px);
    text-align: left;
  }
}